body {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  
  /* Title Section */
  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    color: #000000;
    padding: 10px 0;
    z-index: 10; /* Ensure it appears above videos */
  }
  
  .video-grid {
    display: grid;
    height: 100vh;
    width: 100vw;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 1fr);
    gap: 0;
  }
  
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  
  .video-1 {
    grid-column: 1 / 4;
    grid-row: 1 / 4;
  }
  
  .video-2 {
    grid-column: 4 / 7;
    grid-row: 1 / 4;
  }
  
  .video-3 {
    grid-column: 1 / 3;
    grid-row: 4 / 6;
  }
  
  .video-4 {
    grid-column: 3 / 5;
    grid-row: 4 / 6;
  }
  
  .video-5 {
    grid-column: 5 / 7;
    grid-row: 4 / 6;
  }
  .titlereport{
    color: rgb(255, 255, 255)!important;
    margin-left: 20px;
    font-weight: bold!important;
    letter-spacing: 2px;
  }
  